@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

/* --- custom classes starts --- */
.is-active {
  color: #1b75bc;
  border-block-end: #1b75bc;
}
/* --- custom classes ends --- */

/* --- @apply directives --- */
.input-text {
  @apply appearance-none border border-secondary-4 text-sm text-secondary-3 w-full px-4 py-3 focus:outline-none;
}
.input-text-login {
  @apply appearance-none border border-secondary-4 text-sm text-secondary-3 w-full px-4 py-3 focus:outline-none;
}

.btn {
  @apply inline-flex items-center justify-center w-full;
}

.progress-bar-container {
  @apply overflow-hidden text-xs flex rounded bg-progressbarcontainer;
}
.progress-bar {
  @apply shadow-none rounded flex flex-col text-center whitespace-nowrap text-white justify-center;
}

.kenya-to-egypt-container-icons {
  @apply rounded-full h-8 w-8 flex items-center justify-center bg-points;
}

.course-video-map-container {
  @apply rounded h-16 flex items-center text-white p-4 cursor-pointer;
}

.arrow-container-course-video {
  @apply rounded-full h-12 w-12 flex items-center justify-center bg-white cursor-pointer;
}

.modal {
  @apply justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none animated fadeIn faster;
}
.modal-container {
  @apply relative w-auto my-6 mx-2 max-w-6xl;
}
.modal-content {
  @apply border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none;
}
.modal-header {
  @apply flex items-start justify-between px-5 py-4 rounded-t border-b;
}
.modal-body {
  @apply px-5 py-4 rounded-t;
}
.modal-footer {
  @apply flex items-center justify-center p-4 rounded-b border-t;
}
.modal-background {
  @apply opacity-50 fixed inset-0 z-40 bg-black;
}
.modal-close-btn {
  @apply p-1 outline-none focus:outline-none;
}
.btn-edit-course {
  @apply bg-refresh text-white rounded inline-flex items-center justify-center cursor-pointer;
}
.btn-primary-md {
  @apply bg-refresh text-white rounded cursor-pointer py-2 px-4;
}
.btn-primary-disabled-md {
  @apply bg-blue-500 text-white py-2 px-4 rounded opacity-50 cursor-not-allowed;
}
.btn-secondary-md {
  @apply bg-gray-200 rounded cursor-pointer py-2 px-4;
}

.carousel-arrow-container-course-map {
  @apply rounded-full h-12 w-12 flex items-center justify-center bg-primary cursor-pointer;
}

.course-name-inside-carousel {
  @apply absolute text-base text-white w-full text-center;
}

.select {
  @apply block pl-3 pr-10 py-3 border-0 text-sm font-medium focus:outline-none focus:ring-primary focus:border-primary rounded-md;
}

.table-col-th {
  @apply px-6 py-3 text-left text-sm text-secondary-4 font-normal tracking-wider;
}
.table-col-td {
  @apply px-6 py-4 whitespace-nowrap text-sm font-medium text-primary-black;
}
.table-col-td-without-color {
  @apply px-6 py-4 whitespace-nowrap text-sm font-medium;
}

.checkbox {
  @apply h-5 w-5 text-primary rounded-sm bg-secondary-2 border-0;
}

.data-entry-action-btn {
  @apply text-white inline-flex items-center justify-center;
}

.points-entry-container {
  @apply bg-secondary-3 px-5 py-4 text-white text-sm flex items-center justify-between;
}

.enter-data-steps-input {
  @apply max-w-lg block w-full focus:ring-primary focus:border-primary text-sm font-medium border-secondary3 rounded-md;
}

.enter-data-activity-select {
  @apply focus:ring-primary focus:border-primary block w-full text-sm font-medium border-secondary3 rounded-md;
}

.enter-data-activity-input {
  @apply max-w-lg block w-full focus:ring-primary focus:border-primary sm:max-w-xs text-sm font-medium border-secondary3 rounded-md;
}

.nav-arrow-container-my-data-chart {
  @apply rounded-md h-12 w-12 flex items-center justify-center bg-primary cursor-pointer;
}
/* --- END * @apply directives --- */

/* --- For hiding default arrow left and right of Carousel on Course Map screen --- */
.course-map-carousel-container .BrainhubCarousel__arrowLeft {
  display: none;
}
.course-map-carousel-container .BrainhubCarousel__arrowRight {
  display: none;
}
/* --- END * For hiding default arrow left and right of Carousel --- */

/* --- Animation for Modal --- */
.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.faster {
  -webkit-animation-duration: 500ms;
  animation-duration: 500ms;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

.fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
/* --- End * Animation for Modal --- */

/* --- Custom Dot Carousel --- */
.slides {
  padding: 0;
  width: 545px;
  height: 425px;
  display: block;
  margin: 0 auto;
  position: relative;
}

.slides * {
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

.slides input {
  display: none;
}

.slide-container {
  display: block;
}

.slide {
  top: 0;
  opacity: 0;
  width: 545px;
  height: 425px;
  display: block;
  position: absolute;

  transform: scale(0);

  transition: all 0.7s ease-in-out;
}

.slide img {
  width: 100%;
  height: 100%;
}

.nav label {
  width: 200px;
  height: 100%;
  display: none;
  position: absolute;

  opacity: 0;
  z-index: 9;
  cursor: pointer;

  transition: opacity 0.2s;

  color: #fff;
  font-size: 156pt;
  text-align: center;
  line-height: 380px;
  font-family: "Varela Round", sans-serif;
  background-color: rgba(255, 255, 255, 0.3);
  text-shadow: 0px 0px 15px rgb(119, 119, 119);
}

.slide:hover + .nav label {
  opacity: 0.5;
}

.nav label:hover {
  opacity: 1;
}

.nav .next {
  right: 0;
}

input:checked + .slide-container .slide {
  opacity: 1;

  transform: scale(1);

  transition: opacity 1s ease-in-out;
}

input:checked + .slide-container .nav label {
  display: block;
}

.nav-dots {
  width: 100%;
  bottom: 9px;
  height: 11px;
  display: block;
  position: absolute;
  text-align: center;
}

.nav-dots .nav-dot {
  top: -5px;
  width: 11px;
  height: 11px;
  margin: 0 4px;
  position: relative;
  border-radius: 100%;
  display: inline-block;
  background-color: #fff;
}

.nav-dots .nav-dot:hover {
  cursor: pointer;
  background-color: #1f89e593;
}

input#img-1:checked ~ .nav-dots label#img-dot-1,
input#img-2:checked ~ .nav-dots label#img-dot-2,
input#img-3:checked ~ .nav-dots label#img-dot-3,
input#img-4:checked ~ .nav-dots label#img-dot-4,
input#img-5:checked ~ .nav-dots label#img-dot-5,
input#img-6:checked ~ .nav-dots label#img-dot-6 {
  /* background: rgba(0, 0, 0, 0.8); */
  background: #1f8ae5;
}
/* --- End * Custom Dot Carousel --- */

/* --- Carousel styles for Course Details pop-up --- */
.course-details-carousel-container .BrainhubCarousel__dots {
  /* display: flex;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 0;
  line-height: 0; */
  position: absolute;
  bottom: 27px;
}

.course-details-carousel-container
  .BrainhubCarousel__dots
  .BrainhubCarousel__dot:before {
  /* content: ''; */
  /* display: block; */
  width: 10px;
  height: 10px;
  /* border-radius: 50%; */
  /* padding: 0; */
  /* border: none; */
  background: #fff;
}

.course-details-carousel-container
  .BrainhubCarousel__dots
  .BrainhubCarousel__dot {
  /* outline: 0; */
  padding: 3px;
  /* border: none;
  opacity: 0.5;
  font-size: 0;
  cursor: pointer;
  -webkit-appearance: none; */
}

.course-details-carousel-container
  .BrainhubCarousel__dot.BrainhubCarousel__dot--selected::before {
  background: #1f8ae5 !important;
}

.course-details-carousel-container
  .BrainhubCarousel__dots
  .BrainhubCarousel__dot {
  /* outline: 0;
  padding: 10px;
  border: none; */
  opacity: 1;
  /* font-size: 0;
  cursor: pointer;
  -webkit-appearance: none; */
}
/* --- END * Carousel styles for Course Details pop-up --- */

/* --- Scrolling table elements with fix thead --- */
.view-all-team-table-container table thead,
.view-all-team-table-container table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.view-all-team-table-container table tbody {
  display: block;
  height: 500px;
  overflow-y: auto;
}

.data-entry-table-container table thead,
.data-entry-table-container table tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.data-entry-table-container table tbody {
  display: block;
  max-height: 500px;
  overflow-y: auto;
}
/* --- END * Scrolling table elements with fix thead --- */

/* --- Range Slider --- */
/* .slide-container {
  width: 100%;
} */

.slider {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  background: #e6e8f2;
  /* outline: none;
  opacity: 0.7;
  -webkit-transition: 0.2s;
  transition: opacity 0.2s; */
}

/* .slider:hover {
  opacity: 1;
} */

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 44px;
  height: 44px;
  /* border: 0; */
  /* border-radius: 50%; */
  /* background-image: url("./assets/img/glass-of-water-slider-thumb-with-bg.png"); */
  background-size: contain;
  /* background-position: center center; */
  /* background-repeat: no-repeat; */
  /* background-color: #60e7ff; */
  /* box-shadow: -405px 0 0 400px #7ee076; */
  cursor: pointer;
  /* padding: 10px; */
}

.slider::-moz-range-thumb {
  width: 44px;
  height: 44px;
  /* border: 0; */
  /* border-radius: 50%; */
  /* background-image: url("./assets/img/glass-of-water-slider-thumb-with-bg.png"); */
  background-size: contain;
  /* background-position: center center;
  background-repeat: no-repeat; */
  /* background-color: #60e7ff; */
  /* box-shadow: -405px 0 0 400px #7ee076; */
  cursor: pointer;
}
/* --- END * Range Slider --- */

/* input[type="range"]::-webkit-slider-thumb {
  width: 15px;
  -webkit-appearance: none;
  appearance: none;
  height: 15px;
  cursor: ew-resize;
  background-color: #fff;
  background-image: url("./assets/img/glass-of-water-slider-thumb.svg");
  box-shadow: -405px 0 0 400px #605e5c;
  border-radius: 50%;
} */

/* --- Multiple Range Sliders --- */
.slider-water {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #7ee076),
    color-stop(0, #e6e8f2)
  );
}
.slider-water::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/glass-of-water-slider-thumb-with-bg.png"); */
  background-image: url("./assets/img/water.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-water:hover::-webkit-slider-thumb {
  border: 0;
  /* background: transparent; */
}
.slider-water::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/water.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-water:hover::-moz-range-thumb {
  border: 0;
}

.slider-fruit {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #ff8327),
    color-stop(0, #e6e8f2)
  );
}
.slider-fruit::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/fruits-and-vegetables-fruit-food-grapes-slider-thumb.png"); */
  background-image: url("./assets/img/fruit.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-fruit:hover::-webkit-slider-thumb {
  border: 0;
}
.slider-fruit::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/fruit.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-fruit:hover::-moz-range-thumb {
  border: 0;
}

.slider-veges {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #ff4a4a),
    color-stop(0, #e6e8f2)
  );
}
.slider-veges::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/fruits-and-vegetables-vegetable-food-carrot-slider-thumb.png"); */
  background-image: url("./assets/img/veges.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-veges:hover::-webkit-slider-thumb {
  border: 0;
}
.slider-veges::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/veges.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-veges:hover::-moz-range-thumb {
  border: 0;
}

.slider-foods {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #7ee076),
    color-stop(0, #e6e8f2)
  );
}
.slider-foods::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/fastfood-burger-cola-slider-thumb.png"); */
  background-image: url("./assets/img/foods.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-foods:hover::-webkit-slider-thumb {
  border: 0;
}
.slider-foods::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/foods.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-foods:hover::-moz-range-thumb {
  border: 0;
}

.slider-sugar {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #fd8326),
    color-stop(0, #e6e8f2)
  );
}
.slider-sugar::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/kids-baby-food-spoon-jar-slider-thumb.png"); */
  background-image: url("./assets/img/sugar.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-sugar:hover::-webkit-slider-thumb {
  border: 0;
}
.slider-sugar::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/sugar.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-sugar:hover::-moz-range-thumb {
  border: 0;
}

.slider-sleep {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #7ee076),
    color-stop(0, #e6e8f2)
  );
}
.slider-sleep::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/halloween-moon-clouds-night-slider-thumb.png"); */
  background-image: url("./assets/img/sleep.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-sleep:hover::-webkit-slider-thumb {
  border: 0;
}
.slider-sleep::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/sleep.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-sleep:hover::-moz-range-thumb {
  border: 0;
}

.slider-screentime {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #fd4a4a),
    color-stop(0, #e6e8f2)
  );
}
.slider-screentime::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/computers-and-hardware-screen-pc-smart-phone-slider-thumb.png"); */
  background-image: url("./assets/img/screentime.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-screentime:hover::-webkit-slider-thumb {
  border: 0;
}
.slider-screentime::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/screentime.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-screentime:hover::-moz-range-thumb {
  border: 0;
}

.slider-mindfulness {
  -webkit-appearance: none;
  width: 250px;
  height: 6px;
  border-radius: 6px;
  /* background: #e6e8f2; */
  border: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0, #fd8326),
    color-stop(0, #e6e8f2)
  );
}
.slider-mindfulness::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 28px;
  height: 28px;
  /* background-image: url("./assets/img/brain-slider-thumb.png"); */
  background-image: url("./assets/img/mindfulness.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-mindfulness:hover::-webkit-slider-thumb {
  border: 0;
}
.slider-mindfulness::-moz-range-thumb {
  width: 28px;
  height: 28px;
  background-image: url("./assets/img/mindfulness.svg");
  background-size: contain;
  cursor: pointer;
  border: 0;
}
.slider-mindfulness:hover::-moz-range-thumb {
  border: 0;
}
/* --- END * Multiple Range Sliders --- */

/* --- React Grid DND --- */
.container {
  display: flex;
  touch-action: none;
  width: 800px;
  margin: 1rem auto;
}

.dropzone {
  flex: 1;
  height: 400px;
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  /* border-radius: 1rem; */
}

.dropzone.left {
  margin-right: 10px;
}

.grid-item {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.grid-item-content {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
  border-radius: 50%;
}
/* --- END * React Grid DND --- */

/* --- map marker container --- */
/* .marker {
  position: absolute;
  transition: all 1s ease-in-out;
} */

/* avatar styles starts */
.bottom {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 1;
}
.shoes {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
}
.top {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
}
.hands {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 2;
}
.head {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 3;
}
.hair {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 5;
}
.eyes {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 4;
}
.nose {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 5;
}
.mouth {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 5;
}
.beard {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 6;
}
.eyeBrow {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 4;
}
.glasses {
  position: absolute;
  top: 25px;
  left: 25px;
  z-index: 6;
}
.avatar_container {
  position: relative;
  top: 0px;
  left: 0px;
}
.bottom2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
}
.shoes2 {
  position: relative;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.top2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.hands2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 2;
}
.head2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 3;
}
.hair2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
}
.eyes2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
}
.nose2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
}
.mouth2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 5;
}
.beard2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 6;
}
.eyeBrow2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 4;
}
.glasses2 {
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 6;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.member-list-container::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.member-list-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.enter-data-modal-body {
  max-height: 500px;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.enter-data-modal-body::-webkit-scrollbar {
  display: none;
}

.faq-container {
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.faq-container::-webkit-scrollbar {
  display: none;
}

.login-bg-image-container {
  -webkit-transition: background-image 0.2s ease-in-out;
  transition: background-image 0.2s ease-in-out;
}

/* chart tooltip custom class */
.tooltipMainDiv {
  /* height: 150px; */
  /* width: 160px; */
  width: auto;
  background-color: #ffffff;
  pointer-events: none;
  position: absolute;
  opacity: 1;
  transform: translate(-50%, 0);
  transition: all 0.5s ease;
  border-color: #717b89;
  box-shadow: #717b89;
}

.titleDivClass {
  background-color: #f2f3f7;
  color: #717b89;
  text-align: left;
}

.titleHeadingClass {
  padding: 5px;
  margin: auto;
  margin-left: 8px;
  text-align: center;
}

.bodyDivClass {
  background-color: #ffffff;
  color: #082147;
  height: auto;
  width: auto;
  text-decoration: solid;
  text-align: left;
}

.colorCircle {
  height: 10px;
  width: 10px;
  display: inline-block;
  border-radius: 50%;
}

.colorTd {
  vertical-align: top;
  text-align: left;
}

.td-lable-style {
  margin-left: 3px;
  margin-right: 5px;
}

.course-details-overview {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.course-details-overview::-webkit-scrollbar {
  display: none;
}

/* Form Related Start */
.form-group {
  margin-bottom: 1rem;
}
.control-label,
.control-label-top {
  font-size: 0.88rem;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #18203a;
}
.label-mandatory {
  color: red;
}
.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 1.25rem + 2px);
  font-size: 0.875rem;
  padding: 0.625rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #18203a;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cad1d7;
  border-radius: 0.375rem;
  box-shadow: none;
  transition: all 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.has-danger .form-control {
  border-color: red !important;
}
.has-danger .text-help {
  font-size: 0.75rem;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
  color: red;
}
/* Form Related End */
.min-height2_5 {
  min-height: 2.5rem;
}

.text-shadow {
  text-shadow: 1px 1px #000000;
}

.mapmarker-container {
  overflow: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.mapmarker-container::-webkit-scrollbar {
  display: none;
}

.coursemap-wrapper {
  overflow: hidden;
  height: 624px;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;
}

.coursemap-wrapper > img {
  display: inline-block;
  height: 150%;
  width: 150%;
  position: relative;
  /* top: -50%; */
}

.numbers {
  line-height: 1em;
  text-align: center;
  /* margin: 40px auto; */
  overflow: hidden;
}

.numbers__window {
  display: inline-block;
  overflow: hidden;
  width: 0.6em;
  height: 1em;
}

.numbers__window__digit {
  font: inherit;
  word-break: break-all;
  display: block;
  width: 12px;
  /* padding: 0 0.64em 0 0; */
  margin: 0 auto;
  overflow: inherit;
  animation: counting 0.4s steps(10) forwards infinite;
}

.numbers__window__digit::before {
  content: attr(data-fake);
  display: inline-block;
  width: 100%;
  height: auto;
}

@keyframes counting {
  100% {
    transform: translate3d(0, -10em, 0);
  }
}

.numbers__window__digit--1 {
  animation-iteration-count: 7;
}
.numbers__window__digit--2 {
  animation-iteration-count: 7;
}
.numbers__window__digit--3 {
  animation-iteration-count: 7;
}
.numbers__window__digit--4 {
  animation-iteration-count: 6;
}
.numbers__window__digit--5 {
  animation-iteration-count: 3;
}
